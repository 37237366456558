@import 'Base.scss';

.MuiToolbar-dense {
  min-height: 56px !important;
}

.MuiButton-root {
  text-transform: none;
  border-radius: 4px;
}

.MuiAppBar-colorPrimary {
  background-color: $primary-navbar-color !important;
}

.MuiIconButton-root:hover,
.MuiButton-root:hover {
  background-color: transparent;
}

.MuiButton-outlined,
.MuiButton-outlined:hover {
  color: $button-color;
  border: 1px solid $button-color;
}

.MuiButton-contained,
.MuiButton-contained:hover {
  background-color: $button-color;
  color: $button-text-color;
}

.MuiTypography-h3 {
  font-family: $primary-font-family;
  font-size: 3.53vh;
  font-weight: $semibold-weight;
  line-height: 4.4vh;
}

.MuiTypography-h4 {
  font-family: $primary-font-family;
  font-size: 2.6vh;
  font-weight: $semibold-weight;
  line-height: 2.9vh;
}

.MuiTypography-h5 {
  font-family: $primary-font-family;
  font-size: 2vh;
  font-weight: $medium-weight;
  line-height: 2.9vh;
  color: $tangora-color;
}

.MuiTypography-h6 {
  font-family: $primary-font-family;
  font-size: 1.5vh;
  font-weight: $medium-weight;
  line-height: 2vh;
  color: $text-light-black-color;
}

.MuiTypography-subtitle1 {
  font-family: $primary-font-family;
  font-size: 1.4vh;
  font-weight: $normal-weight;
  line-height: 1.9vh;
  color: $table-header-color;
}

.Header .Navigation-Menu .Navigation-Item.Active .Navigation-Label,
.Header .Navigation-Menu .Navigation-Item.Active .Navigation-Label:hover {
  color: $primary-nav-text-color;
}

.Header .Navigation-Menu .Navigation-Item.Active {
  border-top: 2px solid $primary-nav-text-color;
}

.MuiPaginationItem-root {
  border-radius: 0px;
  font-family: $primary-font-family;
  height: 32px !important;
}

.MuiPaginationItem-ellipsis {
  line-height: 44px;
}

.MuiPaginationItem-page.Mui-selected {
  color: $button-color;
  background-color: transparent;
  border-top: 2px solid $button-color;
  padding-top: 10px;
}

.MuiInput-underline:hover:not(.Mui-disabled):before,
.MuiInput-underline:after {
  border-bottom: none;
}

.MuiPaginationItem-icon {
  margin-top: 10px;
}

.MuiRadio-colorSecondary.Mui-checked,
.MuiInput-underline:after {
  color: $button-color;
}

.MuiTabs-indicator {
  background-color: $button-color !important;
}

.Toastify__toast--error {
  background-color: $primary-error-bg !important;

  .Toastify__toast-body {
    color: $primary-error-font-color;
  }
}

.Toastify__toast-body {
  font-size: 13px;
  line-height: 20px;
  font-family: $primary-font-family;
}
