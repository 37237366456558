@import '../../Base.scss';

$DEFAULT_BACKGROUND: #ffffff;
$DEFAULT_PLACEHOLDER_COLOUR: #7a869a;
$DEFAULT_BORDER_COLOUR: #dfe1e6;

%DEFAULT_TEXTFIELD_STYLE {
  display: flex;
  padding: 8px 12px;
  border-radius: 4px;
  align-items: center;
  background-color: $DEFAULT_BACKGROUND;
  border: 1px solid $DEFAULT_BORDER_COLOUR;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);

  .MuiInput-root {
    width: 100%;
  }

  .MuiTextField-root {
    width: 100%;
  }

  .MuiInput-underline {
    &::before {
      border-bottom: 0;
    }
    &::after {
      display: none;
    }
    &:hover:not(.Mui-disabled):before {
      border-bottom: 0;
    }
  }

  .MuiInputBase-input {
    color: $tangora-color;
    font-style: normal;
    font-weight: $medium-weight;
    font-size: 13px;
    line-height: 24px;

    &::placeholder {
      color: $DEFAULT_PLACEHOLDER_COLOUR;
    }
  }
}

.page-title {
  color: $primary-text-color;
}

.common {
  &.switch {
    .react-switch-handle {
      box-shadow: none !important;
    }

    span {
      margin-left: 8px;
      font-weight: $medium-weight;
      font-size: 14px;
      line-height: 24px;
      color: $tangora-color;
    }
  }

  &.label {
    font-style: normal;
    font-weight: $medium-weight;
    font-size: 12px;
    line-height: 24px;
    color: #6b778c;

    .red {
      color: #aa2b2b;
    }
  }

  &.textfield,
  &.textarea {
    height: 40px;
    @extend %DEFAULT_TEXTFIELD_STYLE;

    // The padding is blocking view on Safari browser
    input {
      padding: 0px;
    }
  }
}

.MuiTouchRipple-root {
  display: none;
}

.home-user-greeting {
  color: $primary-text-color;
}

.mt3-5 {
  margin-top: 1.5rem;
}
