@import 'Base.scss';

#simple-menu,
#mobile-menu {
  margin-top: 34px;
  margin-left: -7px;

  .MuiButtonBase-root.Mui-focusVisible {
    background-color: $primary-white;
  }

  .MuiList-padding {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .menu-items {
    height: 40px;
    border-top: 1px solid #dfe1e6;
    box-sizing: border-box;
    display: flex;

    &:hover {
      background-color: rgba(0, 0, 0, 0.08) !important;
    }
  }
}

.menu-items-title {
  margin-left: 8px;
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #042040;
}

.menu-items:first-child > .menu-icons {
  margin-left: -5px;
}

.shadow-hover:focus:after,
.shadow-hover:hover:after {
  opacity: 0;
}
