#auth-container {
  .firebaseui-info-bar.firebaseui-id-info-bar {
    z-index: 1300;
  }
  ul.firebaseui-idp-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;

    li {
      & button {
        display: flex;
        align-items: center;
        justify-content: center;

        & .firebaseui-idp-icon {
          width: 24px;
          height: 24px;
        }
      }

      [data-provider-id='apple.com'],
      [data-provider-id='microsoft.com'],
      [data-provider-id='google.com'],
      [data-provider-id='facebook.com'],
      [data-provider-id='twitter.com'] {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        min-width: 48px;
        width: 48px;
        height: 48px;
        box-shadow: none;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 50%;
        & .firebaseui-idp-text-long,
        & .firebaseui-idp-text-short {
          display: none;
        }
      }

      [data-provider-id='facebook.com'] {
        visibility: hidden;
      }

      [data-provider-id='password'] {
        width: 304px;
        max-width: inherit;
        box-shadow: none;
        border: 1px solid rgba(0, 0, 0, 0.5);
        & .firebaseui-idp-text-long,
        & .firebaseui-idp-text-short {
          color: rgba(0, 0, 0, 0.54);
          font-weight: 700;
          font-size: 16px;
        }
      }
    }
  }

  .firebaseui-card-actions {
    .firebaseui-form-links {
      display: none;
    }
  }
}
