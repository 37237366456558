@import 'Base.scss';

.common-icon-btn {
  background: $primary-text-color;
  border: 1px solid $primary-blue;
  color: $primary-blue;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 6px 8px;
  font-style: normal;
  font-weight: $medium-weight;
  font-size: 14px;
  line-height: 20px;
  font-family: $primary-font-family;
  height: 40px;
  text-transform: capitalize;

  &:hover {
    background: $primary-text-color;
    border: 1px solid $primary-blue;
    color: $primary-blue;
  }

  .MuiButton-label {
    margin-right: 4px;
    .MuiButton-startIcon {
      margin-right: 0;
    }
  }

  .icon {
    margin: 14.67px 6.67px;
  }
}
