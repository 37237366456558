@import 'Base.scss';

.profile-pic-with-initials {
  height: 32px;

  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;

  color: $primary-white;

  .mb-4 {
    margin-bottom: 4px;
  }

  svg {
    width: 20px;
    height: 20px;
  }
}

.profile-pic {
  width: 32px;
  height: 32px;

  background: #a4c4b0;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.profile-btn {
  width: 32px;
  height: 32px;

  background: #a4c4b0;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 5px;
  text-align: center;
  text-transform: uppercase;
}
