:root {
  --nav-bg-color: #000;
  --nav-text-color: #fff;
  --primary-text-color: #fff;
  --button-color: #000;
  --button-font-color: #000;
}
$primary-color: #8d43fb;
$primary-navbar-color: var(--nav-bg-color);
$primary-nav-text-color: var(--nav-text-color);
$primary-background-color: #ececec;
$primary-text-color: var(--primary-text-color);
$secondary-color: #ececec;
$title-color: #828286;
$button-color: var(--button-color);
$button-text-color: var(--button-font-color);
$native-black: #000000;
$primary-error-bg: #ffebe6;
$primary-error-font-color: #bf2600;
$primary-success-font-color: #00875A;


$primary-black: #0f0f13;
$primary-white: #ffffff;
$primary-grey: #949599;
$primary-blue: #0073e6;
$primary-blue-transparent: rgba(0, 115, 230, 0.1);

$primary-filter-blue: sepia(96%) saturate(2570%) hue-rotate(198deg) brightness(95%) contrast(101%);
$primary-filter-white: sepia(100%) saturate(0%) hue-rotate(184deg) brightness(215%) contrast(101%);
$primary-filter-maroon: sepia(88%) saturate(2125%) hue-rotate(344deg) brightness(87%) contrast(101%);

$background-pages-light-blue-color: #f7f9fc;

$text-slate-grey-color: #7a869a;
$text-light-black-color: #3c4257;
$text-dark-grey: #4f566b;
$text-disabled-color: rgba(4, 32, 64, 0.15);
$text-yellow-dark: #eea800;
$btn-yellow-light: #fdf6e6;
$text-red-text: #aa2a2a;
$btn-red-light: #f7eaea;
$btn-gray-light: #F2F3F5;
$text-green-dark: #24b47e;
$btn-green-light: #e9f8f2;
$light-blue-color: #edf4fe;
$tab-unselected-color: #7a869a;
$red-color: #f56565;
$text-light-grey: #95a3b2;
$text-title-dark: #1a1f36;
$text-footer-color: #262626;

$calendar-dark-text: #172b4d;
$calendar-days-text: #6b778c;

$table-header-color: #6e788a;
$table-body-color: #55687d;
$table-border-color: #dedfe2;
$tangora-color: #042040;
$white-grey: #f7f7f7;
$dark-grey-text: #8a8f98;
$footer-signup-color: #f6f8f9;

$danger-red-color: #c71c1c;

$box-primary-background: #ffffff;
$box-border: #e3e8ee;
$input-border: #dfe1e6;
$input-border-error: #c84427;
$light-grey-border: #f1f2f3;
$tab-border: #f9fafb;

$primary-font-family: Inter;
$primary-font-family: 'Inter', sans-serif;

$normal-weight: 400;
$medium-weight: 500;
$semibold-weight: 600;
