@import '../../Base.scss';

.MuiInput-root,
.MuiInput-underline {
  min-width: 160px;
  height: 32px;
}

.MuiInput-root:hover,
.MuiInput-underline:hover {
  border-bottom: none;
}

.MuiInput-underline:before {
  border-bottom: none;
}

.MuiSelect-select {
  border: 1px solid $table-border-color;
  border-radius: 4px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  padding: 6px 12px;
  color: $text-light-black-color;
  font-size: 14px;
  line-height: 20px;
}

.month-filter-wrapper {
  .MuiSelect-select {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.calendar-filter-wrapper {
  padding: 6px 12px;
  border: 1px solid $table-border-color;
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
  img {
    max-width: 14px;
    max-height: 14px;
    margin-top: 2px;
    margin-right: 10px;
  }
  span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $text-light-black-color;
    font-family: $primary-font-family;
  }
}

.filter-container {
  @media (max-width: 639px) {
    flex-direction: column;
    .mobile-content-start {
      flex-direction: column;
      margin-top: 14px;
    }
  }
  @media (max-width: 767px) {
    .mobile-content-start {
      align-items: flex-start;
    }
    .month-filter-wrapper {
      margin-bottom: 14px;
    }
    .calendar-filter-wrapper {
      box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }
  }
}

th {
  svg {
    cursor: pointer;

    &.default {
      path {
        fill: #8792a2;
      }
    }

    &.filled {
      path {
        fill: $primary-blue;
      }
    }
  }
}

.payment-right-top-table {
  .table-pagination {
    background-color: #f7f9fc;
  }
}

.table-wrapper {
  @media (max-width: 767px) {
    overflow: auto;
  }
  .table {
    width: 100%;
    @media (max-width: 767px) {
      min-width: 900px;
    }
    thead {
      box-shadow: inset 0px -1px 0px #e3e8ee;
    }
    th {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      color: $table-header-color;
      text-align: left;
      padding: 13px 0;
      .th-text {
        margin: 0 5px;
      }
      span {
        &:last-child {
          top: 2px;
        }
      }
    }
    tbody {
      tr {
        &:last-child {
          td {
            border: none;
          }
        }
        &:hover {
          background: #f1f1f1;
        }
      }
      td {
        width: 16.66%;
        font-size: 16px;
        padding: 10px 0;
        border-bottom: 1px solid #f1f2f4;
        .alert-btn {
          font-weight: $medium-weight;
          font-size: 13px;
          line-height: 24px;
          padding: 7px 21px;
          width: 88px;
          border-radius: 6px;
        }
        .yellow-btn {
          background: $btn-yellow-light;
          color: $text-yellow-dark;
        }
        .warning-btn {
          background: $btn-red-light;
          color: $text-red-text;
        }
        .gray-btn {
          color: $text-slate-grey-color;
          background: $btn-gray-light;
        }
        .green-btn {
          color: $text-green-dark;
          background: $btn-green-light;
        }
      }
    }
  }
}

.empty-row {
  height: 60px;
}

.font-600 {
  font-weight: $semibold-weight;
}
.light-text {
  color: $text-slate-grey-color;
}

.pagination {
  bottom: -45px;
  left: 0;
  right: 0;
  background: $background-pages-light-blue-color;
  padding: 0px 35px 10px;
  border-top: 1px solid #dfe1e6;
  .current-range,
  .select-pagination {
    padding-top: 10px;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    padding: 10px 20px;
    .col-left {
      margin-bottom: 24px;
    }
  }
}

.select-pagination {
  margin-left: 16px;
  p {
    margin: 0;
    color: $text-slate-grey-color;
    margin-right: 8px;
  }
  .MuiInputBase-root {
    min-width: auto;
  }
}

.sidebar {
  position: fixed;
  right: 0;
  top: 56px;
  bottom: 0;
  background: $primary-white;
  max-width: 536px;
  width: 100%;
  box-shadow: -1px 0 10px #dfe1e6;
  overflow: auto;
  .print-action-btn {
    border: 1px solid #dfe1e6;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    width: 32px;
    height: 32px;
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.invoice-print-action {
  border-bottom: 1px solid #dfe1e6;
  padding: 12px 24px;
}

.invoice-print-body {
  padding: 40px 24px 30px 24px;
}

.left-text-col {
  h1 {
    margin: 0;
  }
  p {
    margin: 0;
    font-size: 16px;
  }
}

.user-detail-row {
  margin-top: 40px;
  .user-detail-col {
    flex-basis: 50%;
    max-width: 50%;
    h5 {
      margin: 0 0 10px;
      font-size: 16px;
    }
  }
  .amount-box {
    background: $light-blue-color;
    padding: 12px;
    border-radius: 6px;
    flex-basis: 50%;
    max-width: 50%;
    h2 {
      margin: 0;
      color: #3171de;
      font-size: 28px;
    }
  }
}

.mt-3 {
  margin-top: 8px;
}

.mt-4 {
  margin-top: 14px;
}

.mr-5 {
  margin-right: 50px;
}

.invoice-bill-table {
  margin-top: 24px;
  table {
    th {
      text-align: left;
      font-weight: normal;
      text-transform: uppercase;
      padding: 10px 0;
      border-top: 1px solid $primary-background-color;
      border-bottom: 1px solid $primary-background-color;
      font-size: 13px;
    }
  }
  td {
    font-size: 13px;
    padding: 10px 0;
    border-bottom: 1px solid $primary-background-color;
  }
}

.payment-info-col {
  margin-top: 30px;
  .payment-info {
    flex-basis: 58%;
  }
  .balance-col {
    flex-basis: 42%;
  }
  p {
    color: #3171de;
    margin: 5px 0 0;
    font-weight: 500;
    font-size: 12px;
  }
}

.total-due-info {
  margin: 30px 0;
  thead {
    th {
      font-weight: 500;
      font-size: 13px;
    }
  }
  tbody {
    td {
      text-align: center;
      background: $primary-background-color;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      padding: 4px 0;
      &:last-child {
        font-weight: 600;
      }
    }
  }
}

.footer-deatil {
  padding-top: 15px;
  &::before {
    position: absolute;
    background: #ddd;
    width: 80%;
    height: 1px;
    content: '';
    left: 0;
    top: 0;
  }
  span {
    font-size: 13px;
  }
  .invoice-time {
    padding-left: 20px;
    &::before {
      position: absolute;
      background: #ddd;
      width: 1px;
      height: 100%;
      content: '';
      left: 10px;
    }
  }
}

.month-filter-wrapper,
.basic-filter-wrapper {
  .MuiPopover-paper {
    min-width: 160px !important;
    border: 1px solid #dedfe2;
    box-sizing: border-box;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    .MuiMenu-list {
      padding: 0;
      .MuiMenuItem-gutters {
        font-size: 14px;
        line-height: 24px;
        color: $tangora-color;
      }
    }
  }
}

.first-col-pad {
  padding-left: 7px !important;
}

.date-range-wrapper {
  border: 1px solid $light-grey-border;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(35, 38, 59, 0.15);
  border-radius: 4px 0px 0px 4px;
  position: absolute;
  top: 50px;
  z-index: 10;

  .rdrDefinedRangesWrapper {
    display: none;
  }
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
  background-color: $button-color;
}

.rdrDay,
.rdrDayToday,
.rdrDayStartOfMonth,
.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
  color: $button-text-color !important;
}

.last-col-arrow {
  min-width: 30px;
  margin-right: 20px;
}
