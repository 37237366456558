@import 'Base.scss';

.Header {
  height: 56px;
  
  .Bar {
    background-color: $primary-black;
  }

  .Logo {
    height: 40px;
    cursor: pointer;
  }

  .Grow {
    flex-grow: 1;
  }

  .Navigation-Menu {
    .Navigation-Item {
      display: inline;
      line-height: 20px;
      padding-top: 15px;
      padding-bottom: 15px;
      cursor: pointer;

      &:first-of-type {
        margin-left: 10px;
      }

      &.Active {
        border-top: 2px $primary-white solid;

        .Menu-Icon {
          filter: $primary-filter-white;
        }

        &:hover {
          border-top: 2px $primary-blue solid;

          .Navigation-Label {
            color: $primary-blue;
          }
        }

        .Navigation-Label {
          color: $primary-white;
        }
      }

      &:hover {
        color: $primary-blue;
        .Navigation-Label {
          color: $primary-blue;
        }

        .Menu-Icon {
          filter: $primary-filter-blue;
        }
      }
      
      a {
        text-decoration: none;

        button {
          padding: 8px;
        }
      }

      .Navigation-Label {
        font-family: $primary-font-family;
        font-weight: $medium-weight;
        font-size: 14px;
        line-height: 20px;
        text-transform: capitalize;
        color: $primary-grey;
        margin: 0px 2px;

        &:hover {
          color: $primary-blue;
        }

        span.Title {
          cursor: pointer;
          font-family: $primary-font-family;
          font-style: normal;
          font-weight: $medium-weight;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .More-Menu {
      margin: 5px 0px 0px 18px;
    }
  }

  .RightSideMenu {
    img {
      &:hover {
        filter: $primary-filter-blue;
      }
      
      &.Active {
        filter: $primary-filter-white;
      }
    }
  }

  .MuiPaper-elevation4 {
    box-shadow: unset;
  }
}
