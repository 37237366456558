@import 'Base.scss';

.common.table-filters-popover {
  &.checkbox {
    .MuiPopover-paper {
      width: 224px;
    }
  }

  .MuiPopover-paper {
    width: 344px;
    padding: 12px;
    margin-top: 10px;
    background: #ffffff;
    border: 1px solid #e5e7eb;
    box-sizing: border-box;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    .common.textfield {
      height: 32px;
      padding: 4px 12px;
      border: 1px solid #e5e7eb;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    }

    .common.select {
      width: 100%;

      .MuiSelect-selectMenu {
        padding: 4px 12px;
        background: #ffffff;
        border: 1px solid #e5e7eb;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        font-weight: $medium-weight;
        font-size: 13px;
        line-height: 24px;
        color: $tangora-color;
        height: 32px;
        display: inline-flex;
        align-items: center;
      }
    }

    .common.checkbox {
      .MuiIconButton-label {
        color: #dfe1e6;

        img {
          width: 16px;
          height: 16px;
        }
      }

      .MuiCheckbox-colorPrimary.Mui-checked {
        .MuiIconButton-label {
          color: $primary-blue;
        }
      }

      .MuiFormControlLabel-label {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 24px;
        color: $tangora-color;
      }
    }

    .footer-btns {
      font-style: normal;
      font-weight: $medium-weight;
      font-size: 13px;
      line-height: 24px;
      padding: 4px 12px;

      &.footer-cancel {
        background: #ffffff;
        border: 1px solid #e5e7eb;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        color: $text-slate-grey-color;
      }
    }
  }
}

.common.textfield {
  .MuiInputBase-input {
    color: #042040;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
  }
}

.table-filters-popover {
  .MuiInput-root,
  .MuiInput-underline {
    height: 24px;
  }
}

.filter-select-dropdown {
  .MuiPopover-paper {
    background: #ffffff;
    border: 1px solid #e5e7eb;
    box-sizing: border-box;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 4px;

    ul {
      padding: 4px 12px;
      display: flow-root;

      li {
        font-family: $primary-font-family;
        background: none;
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;

        span {
          font-family: $primary-font-family;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          color: $tangora-color;
          line-height: 24px;
        }

        span.tick-icon {
          margin-left: 13px;
        }

        &.Mui-selected {
          span {
            color: $primary-blue;
          }

          .tick-icon {
            display: inherit;
            margin-left: 13px;

            svg {
              font-family: $primary-font-family;
            }
          }
        }
      }
    }
  }
}
