@import 'src/Base.scss';

.Home {
  flex-grow: 1;

  .TableContainer {
    max-height: 465px;
  }
}

.MuiTypography-h5 span {
  color: $table-header-color;
}

.billing-amount {
  margin-top: -2px;
}

.card-section {
  .MuiTypography-subtitle1 {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: #6e788a;

    @media screen and (min-width: 1533px) {
      font-size: 14px;
    }

    @media screen and (max-width: 1350px) {
      font-size: 11px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.home-page-container {
  @media screen and (max-width: 1024px) {
    .MuiGrid-item {
      flex-grow: 0;
      max-width: 100%;
      flex-basis: 100%;
    }
  }
}

.failed-payment-prompt {
  background: #fa2e49;
  height: 40px;

  .wrapper {
    margin: auto;
  }

  .text {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    color: #ffffff;
  }

  .close-icon {
    margin: 8px;
    svg {
      width: 16px;
      height: 16px;
      color: #ffffff;
    }
  }

  .update-payment-btn {
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    margin-left: 20px;

    font-family: $primary-font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #042040;
    height: 28px;

    &:hover {
      background: #ffffff;
      color: #042040;
    }
  }
}

.payment-section-message {
  align-items: center;
  justify-content: center;
  background-color: $white-grey;
  width: 100%;
  border-radius: 4px;
  margin: 10px 0px;
  padding: 3px;
  text-align: center;
}

.payment-button-text {
  font-family: $primary-font-family;
  font-weight: $normal-weight;
  width: 100%;

  &:first-child {
    margin-right: 10px;
  }
}

.button-outline-danger {
  border: 1px solid $danger-red-color;
  color: $danger-red-color;

  &:hover {
    border: 1px solid $danger-red-color;
    color: $danger-red-color;
  }
}

.button-contained-danger {
  background-color: $danger-red-color;
  color: $primary-text-color;

  &:hover {
    background-color: $danger-red-color;
    color: $primary-text-color;
  }
}

.icon {
  color: $text-light-black-color;
  width: 1.5vh;
  height: 1.5vh;
}

.configured-service-tile {
  background-color: $primary-white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  padding: 13px 20px;
  margin-bottom: 8px;
}

.configured-service-status {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.configured-service-title {
  font-size: 2.13vh;
  line-height: 3.34vh;
  font-family: $primary-font-family;
  font-weight: 500;
  margin: 0px 0px 0px 5px;
}

.configured-service-address {
  color: $text-dark-grey;
  font-size: 1.67vh;
  line-height: 2.55vh;
  font-weight: 500;
  font-family: $primary-font-family;
  font-style: normal;
}

.configured-service-price {
  font-size: 2.13vh;
  line-height: 3.34vh;
  font-family: $primary-font-family;
  font-weight: 600;
  margin: 0;
}

.configured-service-type {
  color: $text-dark-grey;
  font-size: 1.67vh;
  line-height: 3.34vh;
  font-weight: 500;
  font-family: $primary-font-family;
}

.configured-service-days {
  font-family: $primary-font-family;
  font-weight: 600;
  font-size: 1.28vh;
  line-height: 1.7vh;
}

.configured-service-day-disabled {
  color: $text-disabled-color;
}

.configured-service-day-enabled {
  color: $tangora-color;
}

.configured-service-duration {
  color: $text-dark-grey;
  font-size: 1.67vh;
  line-height: 2.55vh;
  font-weight: 400;
  font-family: $primary-font-family;
}
