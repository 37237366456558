@import '../../Base.scss';

// rdrDayActive
.rdrDay,
.rdrDayToday,
.rdrDayStartOfMonth,
.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge,
{
  background-color: $primary-white;
  color: $button-text-color !important;
  font-family: $primary-font-family;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.rdrSelected, .rdrInRange,
.rdrStartEdge, .rdrEndEdge {
  background-color: $button-color;
  color: $button-text-color !important;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: $button-text-color !important;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.rdrMonthName,
.rdrMonthAndYearPickers {
  color: $calendar-dark-text;
  font-size: 14px;
  line-height: 34px;
  font-weight: bold;
  font-family: $primary-font-family;
  text-align: center;
}

.rdrWeekDay {
  font-family: $primary-font-family;
  font-weight: bold;
  font-size: 11px;
  line-height: 16px;
  color: $calendar-days-text;
}
