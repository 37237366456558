@import '../../Base.scss';

.payment-left-wrapper {
  border: 1px solid $background-pages-light-blue-color;
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.11);
  border-radius: 8px;
}

.payment-left-top {
  padding: 22px;
  background-color: $white-grey;
}

.payment-left-amount-label {
  color: $tangora-color;
  font-size: 16px;
  line-height: 32px;
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: normal;
  white-space: nowrap;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.font13 {
  font-size: 13px;
}

.payment-left-amount {
  color: $tangora-color;
  font-size: 30px;
  line-height: 48px;
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}

.payment-left-bottom {
  padding: 20px;
  background-color: $primary-white;
}

.payment-left-payment-label {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: $text-slate-grey-color;
}

.payment-left-date {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: $tangora-color;
}

.payment-left-autopay-wrapper {
  margin-top: 10px;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: $primary-white;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: $button-color;
}

.MuiSwitch-thumb {
  border: 1px solid $button-color;
}

.switch-close-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: $button-color;
}

.autopay-label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $native-black;
  margin-left: 8px;
}

.add-payment-extra-wrapper {
  margin-top: 5px;
  // padding-left: 8px;

  .MuiSelect-select {
    border: 1px solid $table-border-color;
    border-radius: 4px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
    padding: 9px 20px 9px 12px;
    color: $text-light-black-color;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
  }

  .ach-MuiGrid-item {
    max-height: 90px;
    margin-top: 10px;
    padding-bottom: 16px !important;
  }

  .MuiSelect-icon {
    margin-top: 3px;
  }

  .ach-container {
    max-height: 90px;
    div {
      margin-left: -8px;
    }

    iframe {
      height: 60px !important;
      width: 102.3% !important;
    }
  }
}

// This classname here is an exception as this goes as it is in the payment gateway init method
.paymentgateway.dn {
  height: 250px;
  margin-left: -7px;

  // iframe {
  //   width: 97% !important;

  //   @media (max-width: 1650px) {
  //     width: 96% !important;
  //   }
  // }
}

.btn-inactive {
  cursor: not-allowed;
  background: #e5e5e5 !important;
}

.paymentgateway {
  height: 240px;
  margin-left: -7px;
  width: 78.5%;

  @media (max-width: 1280px) {
    width: 65% !important;
  }

  @media (max-width: 600px) {
    width: 103% !important;
  }

  @media (width: 1030px) {
    width: 65% !important;
  }

  @media (max-width: 950px) {
    width: 100% !important;
  }

  @media (max-width: 780px) {
    width: 107% !important;
  }
}

.payment-button {
  width: 100%;
  height: 40px;
}

.MuiTab-root {
  text-transform: none;
  color: $tab-unselected-color;
  font-family: $primary-font-family;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}

.MuiTabs-root {
  border-bottom: 1px solid $input-border;
}

.MuiTab-textColorInherit.Mui-selected {
  color: $button-color;
}

.PrivateTabIndicator-colorSecondary-9 {
  background-color: $button-color;
}

.make-payment-wrapper {
  padding-bottom: 16px;
  .MuiInput-root,
  .MuiInput-underline {
    height: 41px;
  }
  .MuiSelect-select {
    border: 1px solid $table-border-color;
    border-radius: 4px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
    padding: 8px 20px 8px 12px;
    color: $text-light-black-color;
    font-size: 14px;
    line-height: 20px;

    .list-left-col {
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: $tangora-color;
      font-family: $primary-font-family;
    }
    .list-right-col {
      font-weight: bold;
      font-size: 13px;
      line-height: 24px;
      color: $text-slate-grey-color;
      font-family: $primary-font-family;
    }
  }
}

.payment-heading {
  .page-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $tangora-color;
    margin: 0;
  }
}

.invoice-heading {
  .page-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $primary-text-color;
  }
}

.payment-left-wrapper {
  margin: 16px 0 0;
}

.auto-pay-column {
  padding: 20px;
  border-top: 1px solid $input-border;
  background: white;

  .selected-terms-divider {
    border: 1px solid #d4ddf0;
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  .mt-8 {
    margin-top: 8px;
  }

  .cal-icon {
    color: #4f566b;
    margin-top: 1px;
  }

  .cal-solid-icon {
    color: #042040;
  }
}

.auto-pay-column-buttons {
  padding: 15px 20px;
  border-top: 1px solid $input-border;
  background: white;
}

.edit-background {
  background: #f9fafb;
  border: 1px solid #dfe1e6;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  padding-left: 10px;
  width: 100%;
}

.payment-detail-recipt {
  margin-bottom: 16px;
  &:last-child {
    margin: 0;
  }
  label {
    color: $text-slate-grey-color;
    font-size: 12px;
    line-height: 18px;
  }
  button {
    font-size: 13px;
    line-height: 24px;
    padding: 5px 10px;
  }
  span {
    font-size: 13px;
    margin-top: 2px;
    img {
      margin-right: 3px;
    }
  }

  .MuiSelect-icon {
    display: none;
  }
}

.payment-right-top-wrapper {
  background: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  padding: 24px 36px;
  margin-top: 15px;
}

.payment-gateway-tabs-container {
  .MuiButtonBase-root {
    padding: 0 16px;
    min-width: auto;
    min-height: 30px;
    &:first-child {
      padding-left: 0;
    }
  }

  .MuiGrid-item {
    padding-bottom: 0px;
  }

  .payment-btn {
    padding-top: 25px;

    &:last-child {
      padding-bottom: 16px;
    }

    .MuiGrid-item {
      padding-right: 20px;
      @media (max-width: 767px) {
        padding: 0;
      }
      .payment-button {
        width: 100%;
        font-weight: $normal-weight;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: $button-text-color;
        padding: 8px 0;
      }
    }
  }
  label {
    color: $text-slate-grey-color;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    white-space: nowrap;
  }
  input,
  .payment-input {
    border: 1px solid $input-border;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    font-size: 13px;
    padding: 12px;
    height: 40px;

    input[type='radio'] {
      border: none;
      box-shadow: none;
      margin-right: 13px;
    }
  }
  .payment-input {
    padding: 7px 12px;
  }

  @media screen and (max-width: 1240px) {
    .payment-input {
      padding: 7px 4px;
    }
    input[type='radio'] {
      margin-right: 5px;
      width: 20px;
    }
    .MuiButtonBase-root {
      padding: 0 12px;
      min-width: auto;
      &:first-child {
        padding-left: 0;
        padding-right: 5px;
      }
    }
  }
}

.backend-data {
  .input-wrapper {
    label {
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      color: $calendar-days-text;
      margin-bottom: 6px;
    }
  }
}

.payment-card-action {
  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;
  }
  .edit {
    color: $primary-blue;
  }
  .remove {
    color: $red-color;
    position: relative;
    padding-left: 10px;
  }
}

.card-details {
  font-weight: 500;
  font-size: 13px;
  // font-size: 1.3vh;
  // font-size: 0.95vw;
  // line-height: 24px;
  color: $tangora-color;
  margin-left: 5px;
}

.card-expiry {
  font-size: 12px;
  font-size: 1.2vh;
  font-size: 0.95vw;
  line-height: 16px;
  color: $text-slate-grey-color;
}

.header-text {
  span {
    margin-right: 6px;
    svg {
      width: 12px;
    }
  }
  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $tangora-color;
    margin: 0;
  }
}

.payment-table {
  .table-wrapper {
    border: none;
    margin: 0;
  }
}

.payment-history-wrapper {
  margin-bottom: 50px;
  .pagination {
    border-radius: 0 0 8px 8px;
    padding: 4px 35px;
  }

  .payment-right-top-table {
    .payment-history-label {
      margin-left: 32px;
      .label {
        font-family: $primary-font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #042040;
        margin-left: 5px;
      }
    }

    .table-wrapper {
      padding: 0 30px;
      margin-top: 2px !important;

      th {
        border-top: none;
        background-color: #fff;
      }
    }

    .right-pagination {
      li {
        button {
          @media (max-width: 830px) {
            margin: 0px !important;
          }
        }
      }
    }

    td {
      padding: 0px 0px 8px 0px;
    }

    .MuiTableHead-root {
      th {
        &:nth-child(4) {
          text-align: right;
        }
      }
    }

    .MuiTableBody-root {
      td {
        &:nth-child(3) {
          font-size: 13px;
          line-height: 24px;
        }

        &:nth-child(4) {
          text-align: right;
        }
      }
    }
  }
  .payment-right-top-wrapper {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.transaction-list-card-icon {
  margin-top: 3px;
  margin-right: 5px;
}

.autopay-timing-wrapper {
  font-size: 13px;
  font-weight: 400;
  line-height: 24px;
  color: $tangora-color;

  span {
    margin-left: -5px;
    padding-top: 1px;
  }

  .MuiRadio-colorSecondary {
    &:hover {
      background-color: unset;
    }
  }
}

.simple-default-button {
  color: $text-light-grey;
  font-size: 14px;
  line-height: 20px;
  font-weight: $medium-weight;
}

.add-payment {
  .MuiButtonBase-root {
    width: 312px;
  }
}

.accounting-number-text {
  margin-left: 0px !important;
  margin-top: -10px !important;
  label {
    color: #8a8f98;
  }
}

.accounting-number-dot {
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background: #dfe1e6;
  margin-top: -3px !important;
}

.add-payment-tab {
  // margin-bottom: 10px;

  // iframe {
  //   width: 101% !important;
  //   height: inherit !important;
  // }

  .add-payment {
    padding-top: 16px;
  }

  .MuiTabs-root {
    border-bottom: none;
    width: 460px;

    @media (max-width: 480px) {
      .MuiTabs-root {
        width: 320px;
      }
    }
  }
}

.internal-tabs {
  .MuiTabs-flexContainer {
    width: 100%;
    background: $tab-border;
    box-shadow: inset 0px 1px 4px rgba(26, 31, 54, 0.2);
    border-radius: 6px;
    padding: 5px;
    margin-top: 25px;

    @media (max-width: 850px) {
      width: 55%;
    }

    .MuiButtonBase-root {
      width: 225px;

      @media (max-width: 480px) {
        width: 160px;
      }

      @media (max-width: 850px) {
        width: 120px;
      }
    }

    .Mui-selected {
      background-color: $button-color;
      color: $button-text-color;
      box-shadow: 0px 1px 2px rgba(4, 32, 64, 0.6);
      border-radius: 4px;
    }
  }

  .MuiTabs-indicator {
    display: none;
  }
}

.MuiPopover-paper {
  min-width: auto;

  .MuiMenu-list {
    padding: 0px;
    border: 1px solid $table-border-color;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }
  .select-list {
    padding: 0 16px;
    background: transparent;
    margin-bottom: 4px;
    &:hover {
      background: transparent;
    }
    .check-icon {
      margin-left: 6px;
      width: 18px;
    }
  }
  .list-left-col {
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: $tangora-color;
    font-family: $primary-font-family;
  }
  .list-right-col {
    font-weight: bold;
    font-size: 13px;
    line-height: 24px;
    color: $text-slate-grey-color;
    font-family: $primary-font-family;
  }
}

.modal-style {
  top: 50%;
  left: 50%;
  transform: translate(43%, 60%);
  background-color: $primary-white;
  max-width: 55%;
  padding: 20px 28px;

  @media screen and (max-width: 480px) {
    top: 50%;
    left: 50%;
    transform: translate(6%, 100%);
    background-color: $primary-white;
    max-width: 90%;
    padding: 20px 28px;
  }
}

.modal-payment-message {
  font-size: 14px;
  line-height: 28px;
  font-weight: $semibold-weight;
  text-align: center;
  font-family: $primary-font-family;

  .highlight {
    font-size: 22px;
  }
}

.modal-payment-footer-message {
  font-family: $primary-font-family;
  font-weight: $medium-weight;
  font-size: 14px;
  line-height: 24px;
  max-width: 60%;

  @media screen and (max-width: 480px) {
    max-width: 100%;
  }
}

.modal-icon-container {
  margin: 20px auto;
}

.tnc-error-container {
  padding: 10px 22px !important;
  margin: 15px auto;
}

.payment-method-label {
  opacity: 0;
}

.payment-method-label0 {
  opacity: 1 !important;
}

.autopay-tnc-wrapper {
  .autopay-tnc {
    color: $tangora-color;
    font-family: $primary-font-family;
    font-size: 13px;
    line-height: 16px;
    margin-top: 8px;

    a {
      color: $primary-blue;
    }
  }

  .MuiCheckbox-colorSecondary {
    padding-left: 3px;
    &:hover {
      background-color: unset;
    }
  }
}

.extra-fields-wrapper {
  padding-left: 6px !important;
}

.other-payment-add {
  margin-top: 20px;
  height: 236px;
  margin-left: -17px;
}
.other-payment-add-hide {
  height: 0px;
}

.other-payments-save-wrapper {
  padding-left: 25px !important;
  padding-top: 0px !important;

  .other-payments-save {
    margin-bottom: 16px;

    span {
      font-family: $primary-font-family;
      font-size: 14px;
      line-height: 20px;
      color: $tangora-color;
    }
  }

  input {
    &:focus {
      outline: none;
    }
  }
}

.dot {
  width: 6px;
  height: 6px;
  background: #dfe1e6;
  border-radius: 6px;
  margin-left: 10px !important;
}

.accounting-number-msg {
  font-family: $primary-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #8a8f98;
  margin-left: 5px !important;
  margin-top: -4px;
}

@media screen and (max-width: 992px) {
  .card-details {
    line-height: 16px;
  }

  .card-expiry {
    line-height: 12px;
  }
}

.cvv-confirmation-wrapper,
.payment-msg-wrapper {
  .mt-20 {
    margin-top: 20px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .close-icon {
    text-align: right;
    cursor: pointer;
    svg {
      width: 18px;
      height: 18px;
    }
  }
}

.cvv-confirmation-wrapper {
  width: 512px;
  height: 317px;

  .cvv-upper-section {
    padding: 25px 25px 5px 25px;
    .cvv-title {
      font-family: $primary-font-family;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #042040;
    }

    .cvv-section {
      text-align: center;
      justify-content: center;
      margin-top: 20px;
      margin-left: 20px;

      .ml-45 {
        margin-left: 45px;
      }

      .label {
        font-family: $primary-font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        color: #042040;
      }

      img {
        margin-top: 15px;
      }

      input {
        width: 100%;
        &:focus {
          outline: none;
        }
      }
    }
  }

  .footer-section {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    padding: 12px 24px;
    background: #ffffff;
    box-shadow: 0px -2px 10px rgba(62, 61, 83, 0.1);
    // Footer buttons
    .footer-btns {
      cursor: pointer;
      font-weight: $medium-weight;
      font-size: 14px;
      line-height: 20px;
      border: none;
    }

    .footer-cancel {
      background: none;
      color: #95a3b2;
    }

    .footer-back {
      display: inline-flex;
      padding: 6px 8px;
      height: 32px;
      background: #ffffff;
      border: 1px solid #dfe1e6;
      box-sizing: border-box;
      box-shadow: 0px 1px 2px rgb(0 0 0 / 10%);
      border-radius: 4px;
      color: $text-slate-grey-color;

      .svg {
        width: 16px;
      }

      .back-txt {
        font-style: normal;
        font-weight: $medium-weight;
        font-size: 14px;
        line-height: 20px;
        color: $text-slate-grey-color;
        margin: 0px 4px;
      }
    }

    .footer-proceed {
      &:disabled {
        cursor: not-allowed;
      }
      background: linear-gradient(180deg, #0073e6 0%, #0068cf 100%);
      box-shadow: 0px 2px 5px rgba(60, 66, 87, 0.08), 0px 1px 1px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      padding: 6px 12px;
      color: white;
    }
  }
}

.payment-msg-wrapper {
  text-align: center;
  padding: 12px 20px;
  width: 520px;

  .payment-top-section {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    color: #042040;
    margin-top: 10px;

    .payment {
      font-size: 22px;
    }
  }

  .top-section {
    padding: 0px 58px;
  }

  .bottom-section {
    padding: 25px 45px;
  }

  .payment-mid-section {
    margin-top: 30px;
  }

  .payment-bottom-section {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #042040;
  }
}
