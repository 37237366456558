.App {
  text-align: center;
}

.Temp-Loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.common-padding {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
}