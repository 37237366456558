@import '../../Base.scss';

.table-wrapper {
  width: 100%;
  height: calc(100vh - 280px);

  @media screen and (max-width: 520px) {
    height: calc(100vh + 40px);
  }

  table {
    border-collapse: separate;
    border-spacing: 0 8px;

    th {
      text-align: left;
      font-weight: normal;
      text-transform: none;
      padding: 10px 0px;
      border-top: 1px solid $primary-background-color;
      border-bottom: 1px solid $primary-background-color;
      font-size: 12px;

      .th-text {
        margin: 0px 5px;
      }

      .table-icon {
        margin-top: 3px;
      }
    }
    td {
      width: 16.66%;
      font-size: 16px;
      padding: 0px 0px 8px 0px !important;
      border-bottom: 1px solid $primary-background-color;

      &:hover {
        cursor: pointer;
      }

      .alert-btn {
        font-weight: $medium-weight;
        font-size: 13px;
        line-height: 24px;
        padding: 7px 21px;
        width: 88px;
        border-radius: 6px;
      }
      .yellow-btn {
        background: $btn-yellow-light;
        color: $text-yellow-dark;
      }
      .warning-btn {
        background: $btn-red-light;
        color: $text-red-text;
      }
      .gray-btn {
        color: $text-slate-grey-color;
        background: $btn-gray-light;
      }
      .green-btn {
        color: $text-green-dark;
        background: $btn-green-light;
      }
    }
  }
}

.payment-history-wrapper {
  .table-pagination {
    height: 50px;
    .left-pagination {
      .MuiTablePagination-toolbar {
        margin-left: 32px;
      }
    }

    .right-pagination {
      margin-top: 0px;
      margin-right: 15px;
    }
  }
}

.table-pagination {
  width: 100%;
  // margin-top: -8px;

  .table-rows-per-page-label {
    font-family: $primary-font-family;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $text-slate-grey-color;
    margin-left: 20px;
  }

  .MuiTablePagination-actions {
    display: none !important;
  }

  .MuiSelect-selectMenu {
    background: #fff !important;
  }

  .line {
    height: 1px;
    border: 1px solid #dfe1e6;
  }

  .table-footer {
    padding: 0px !important;
  }

  .left-pagination {
    .MuiTablePagination-toolbar {
      float: left;
      display: flex;
      flex-direction: row-reverse;
      margin-left: 0px;
      padding-left: 0px;

      p:nth-child(4) {
        color: $tangora-color;
        font-size: 14px;
        font-weight: $medium-weight;
        font-family: $primary-font-family;
        margin-right: 16px;
      }

      p:nth-child(2) {
        color: $text-slate-grey-color;
        font-size: 14px;
        font-weight: normal;
        font-family: $primary-font-family;
      }

      .MuiTablePagination-selectRoot {
        left: 100%;
        position: absolute;

        .MuiSelect-selectMenu {
          background: $primary-text-color;
          border: 1px solid $table-border-color;
          border-radius: 4px;
        }
      }
    }

    .MuiTablePagination-actions {
      display: none;
    }
  }

  .right-pagination {
    float: right;

    .MuiPagination-ul {
      .MuiPaginationItem-page {
        .MuiPaginationItem-icon {
          margin-top: 0px;
        }
      }
    }

    li {
      button {
        font-family: $primary-font-family;
        font-style: normal;
        font-weight: $normal-weight;
        font-size: 14px;
        color: $text-slate-grey-color;
        background: none;
        padding-top: 20px;

        .MuiPaginationItem-icon {
          margin-top: 0px;
        }

        &.Mui-selected {
          font-weight: $semibold-weight;
          color: $primary-blue;
          border-top: 2px solid $primary-blue;
          border-radius: 0;
          margin-top: -2px;

          &:hover {
            background-color: unset;
          }

          &:focus-visible {
            background-color: unset;
          }
        }
      }
    }
  }

  .MuiTablePagination-root {
    width: 320px;
  }
}
