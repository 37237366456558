body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.basic-button {
  text-transform: none;
  margin-right: 10px;
}

.byline-text {
  font-size: 1.54vh;
  line-height: 1.87vh;
}

.card-section {
  width: 100%;
  padding: 10px 16px;
  margin-bottom: 15px;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
}

.flex-section {
  padding: 5px 0px;
  border-radius: 4px;
}

.page-bg {
  background-color: #f7f9fc;
}
