@import '../../Base.scss';

.signup-wrapper {
  min-height: calc(100vh - 300px);
  margin-bottom: 70px;
  margin-top: 30px;

  img {
    max-width: 200px;
    max-height: 90px;
  }

  .already-account-wrapper {
    margin-top: 20px;
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    text-align: center;

    span {
      color: $tangora-color;
    }
    a {
      color: $primary-blue;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .sign-up-error-msg {
    width: 450px;
    left: 520px;
    top: 297px;
    background: #ffebe6;
    border-radius: 4px;
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: #c84427;
    padding: 16px;
    margin-bottom: 15px;
  }
}

.setup-form-wrapper {
  background: $primary-white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.11);
  padding: 56px 64px 64px;
  min-width: 580px;

  hr {
    border: 1px solid $input-border;
    margin-bottom: 36px;
  }

  .thank-you-msg {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: $tangora-color;
  }

  .form-data-wrapper {
    label {
      font-family: $primary-font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $text-slate-grey-color;
      min-width: 128px;
      margin-right: 15px;
      margin-bottom: 16px;
    }

    .user-data {
      font-family: $primary-font-family;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $tangora-color;
    }

    .signup-button-wrapper {
      margin-top: 30px;
      .signup-button {
        background: linear-gradient(180deg, #0073e6 0%, #0068cf 100%);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        width: 163px;
        height: 41px;
        border: none;
        color: $primary-white;
        font-weight: $semibold-weight;
        font-size: 14px;
        line-height: 17px;
        cursor: pointer;
      }
    }
  }
}
.setup-footer {
  background-color: $footer-signup-color;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.11);
  padding: 26px 0px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  .footer-social-media {
    width: 25%;
    margin-bottom: 5px;
  }

  a {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #262626;
    margin-bottom: 7px;
    text-decoration: none;
    cursor: pointer;
  }

  span {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 16px;
    text-align: center;
    color: $text-footer-color;
  }
}

.signup-form-wrapper {
  background: $primary-white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  padding: 56px 64px 64px;
  min-width: 580px;

  @media (max-width: 480px) {
    padding: 20px 24px 24px;
    min-width: 300px;
  }

  .title {
    font-family: $primary-font-family;
    font-style: normal;
    font-weight: $semibold-weight;
    font-size: 24px;
    line-height: 29px;
    color: $text-title-dark;
    margin-bottom: 27px;
  }

  .input-wrapper {
    margin-bottom: 16px;

    label {
      font-family: $primary-font-family;
      font-style: normal;
      font-weight: $medium-weight;
      font-size: 12px;
      line-height: 24px;
      color: $text-slate-grey-color;
    }

    input {
      background: $primary-white;
      border: 1px solid $input-border;
      box-sizing: border-box;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
      font-family: $primary-font-family;
      padding: 8px 12px;

      &:focus {
        outline: none;
      }
    }

    .input-error {
      border: 1px solid $input-border-error !important;
    }

    .complexity {
      margin: 5px 0px -10px;
    }
  }

  .signup-button-wrapper {
    margin-top: 30px;
    .signup-button {
      background: linear-gradient(180deg, #0073e6 0%, #0068cf 100%);
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
      border-radius: 4px;
      height: 40px;
      border: none;
      color: $primary-white;
      cursor: pointer;
    }
  }
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  font-family: $primary-font-family;
  font-style: normal;
  font-weight: $normal-weight;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: $text-slate-grey-color;

  a {
    color: $text-slate-grey-color;
    text-decoration: none;
    cursor: pointer;
  }
}
